import React, { useContext, useState, Fragment, useEffect } from 'react';
import { GlobalContext } from '../context';

import DefaultImage from "../assets/img/userimg.png";

import { Dialog, Transition } from '@headlessui/react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

export default function Profile() {
    const { state } = useLocation();

    const { user, isLoggedIn, setUser, setMeter, setMainMeter, setIsLoggedIn } = useContext(GlobalContext);

    let [isOpen, setIsOpen] = useState(false)
    let [popupOpen, setPopupOpen] = useState(false)

    // console.log(user);

    const navigate = useNavigate();

    const logout = () => {
        console.log(isLoggedIn);
        
        localStorage.clear();
        setUser(null);
        setMeter(null);
        setMainMeter(null);
        setIsLoggedIn(false);

        navigate("/login");
    }

    useEffect(() => {
        if (state) {
            setPopupOpen(true);

            setTimeout(() => {
                setPopupOpen(false);
            }, 5000);
        }
    }, []);

    return (
        <>
            <div className='header'>
                {
                    <img
                        src={user.img_url || DefaultImage}
                        alt={user.img_name || "profile picture"}
                        className="w-20 h-20 mx-auto mt-1.5"
                    />
                }

                <p className='text-white text-[13px] text-center mt-3'>{user.name}</p>
                <p className='text-white text-[13px] text-center'>{user.code}</p>
            </div>

            <Transition
                style={{ position: "fixed", bottom: 0, left: "50%", transform: [{ translate: "-50%" }] }}
                show={popupOpen}
                onClick={() => setPopupOpen(false)}
                enter="transition duration-300 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-300 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
            >
                <div className="aira-popup aira-error-success cursor-pointer" onClose={() => setPopupOpen(false)}>
                    <p>
                        {state && state.message}
                    </p>
                </div>
            </Transition>

            <div className='h-min flex flex-1 flex-column items-center justify-center'>
                <div className='body-container'>
                    <div id="profile-menu-section">
                        <Link to="/profile/details" state={user}>
                            <div className='menu-item w-full bg-white shadow-lg border-gray-500 border border-solid p-2 rounded-md text-[13px] cursor-pointer'>
                                Akun Saya
                            </div>
                        </Link>

                        <Link to="/change-password">
                            <div className='menu-item w-full bg-white shadow-lg border-gray-500 border border-solid p-2 rounded-md text-[13px] mt-3 cursor-pointer'>
                                Ganti Kata Sandi
                            </div>
                        </Link>

                        <div className='menu-item w-full bg-white shadow-lg border-gray-500 border border-solid p-2 rounded-md text-[13px] mt-3 cursor-pointer' onClick={() => setIsOpen(true)}>
                            Keluar
                        </div>

                        <div className='h-48 w-full'></div>

                        <Transition show={isOpen} as={Fragment}>
                            <Dialog
                                onClose={() => setIsOpen(false)}
                                className="fixed z-10 inset-0 overflow-y-auto"
                            >
                                <div className="flex items-center justify-center min-h-screen">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-out duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
                                    </Transition.Child>

                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 scale-95"
                                        enterTo="opacity-100 scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 scale-100"
                                        leaveTo="opacity-0 scale-95"
                                    >
                                        <div className="relative bg-white rounded-md p-3 text-[13px] max-w-sm w-full">
                                            <Dialog.Description as="div" className="text-[13px] text-center">
                                                Apakah Anda yakin ingin keluar?
                                            </Dialog.Description>

                                            <div className='flex w-full'>
                                                <button className='flex-row justify-center grow p-2 shadow-md rounded-md mt-2 mx-1 text-white bg-aira-blue' onClick={() => logout()}>Ya</button>
                                                <button className='flex-row justify-center grow p-2 shadow-md rounded-md mt-2 mx-1 text-white bg-aira-blue' onClick={() => setIsOpen(false)}>Tidak</button>
                                            </div>
                                        </div>
                                    </Transition.Child>
                                </div>
                            </Dialog>
                        </Transition>
                    </div>
                </div>
            </div>
        </>
    )
}
