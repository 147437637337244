import * as React from "react"

function ErrorImage(props) {
  return (
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M256 0C114.844 0 0 114.844 0 256s114.844 256 256 256 256-114.844 256-256S397.156 0 256 0zm102.625 313.375c12.5 12.492 12.5 32.758 0 45.25C352.383 364.875 344.188 368 336 368s-16.383-3.125-22.625-9.375L256 301.25l-57.375 57.375C192.383 364.875 184.188 368 176 368s-16.383-3.125-22.625-9.375c-12.5-12.492-12.5-32.758 0-45.25L210.75 256l-57.375-57.375c-12.5-12.492-12.5-32.758 0-45.25 12.484-12.5 32.766-12.5 45.25 0L256 210.75l57.375-57.375c12.484-12.5 32.766-12.5 45.25 0 12.5 12.492 12.5 32.758 0 45.25L301.25 256l57.375 57.375z"
        fill="#af725a"
        fillOpacity={1}
      />
      <path
        d="M170.653 367.035c-16.42-2.755-28.418-19.268-25.802-35.513 1.64-10.187 2.525-11.294 35.548-44.498L211.254 256l-30.855-31.024c-22.857-22.983-31.321-32.048-32.656-34.976-5.917-12.976-3.264-27.407 6.764-36.786 9.656-9.033 23.038-11.167 35.19-5.614 3.313 1.514 11.13 8.782 35.28 32.8L256 211.253l31.024-30.855c24.149-24.017 31.966-31.285 35.28-32.799 12.68-5.795 27.166-3.053 36.482 6.907 9.297 9.938 11.233 23.61 5.09 35.94-1.627 3.265-9.905 12.125-32.66 34.958l-30.48 30.584 30.86 31.03c32.686 32.865 33.537 33.92 35.369 43.83 3.835 20.747-15.369 39.951-36.117 36.116-9.91-1.832-10.965-2.684-43.824-35.364L256 300.746l-31.024 30.855c-22.983 22.857-32.048 31.321-34.976 32.656-3.357 1.531-13.081 3.912-14.648 3.587-.27-.056-2.385-.42-4.7-.809z"
        fill="#af725a"
        fillOpacity={1}
        strokeWidth={0.867797}
      />
      <path
        d="M167.733 365.819c-12.501-3.907-21.571-15.05-22.591-27.754-.581-7.242 1.426-13.857 5.987-19.726 1.98-2.547 16.5-17.603 32.267-33.457l28.667-28.827-30.805-31.067c-35.705-36.008-35.915-36.295-35.883-48.825.015-6.124.407-8.213 2.308-12.301 3.2-6.883 8.534-12.337 15.34-15.686 5.111-2.514 6.534-2.82 13.124-2.815 12.574.008 12.855.214 48.814 35.87L256 212.01l31.04-30.778c35.968-35.665 36.253-35.875 48.816-35.875 6.588 0 8.018.307 13.123 2.82 6.792 3.344 12.124 8.79 15.356 15.686 1.935 4.126 2.308 6.12 2.304 12.3-.008 12.585-.22 12.875-35.89 48.822l-30.79 31.032 29.907 30.148c16.45 16.581 31.14 32.04 32.647 34.351 9.62 14.758 2.758 35.923-14.155 43.666-9.063 4.149-19.936 3.494-27.843-1.677-2.312-1.512-17.788-16.217-34.39-32.677l-30.189-29.93-27.086 26.927c-35.753 35.54-36.623 36.308-43.685 38.489-6.491 2.005-12.113 2.168-17.432.506z"
        fill="#faa381"
        fillOpacity={1}
        strokeWidth={0.867797}
      />
    </svg>
  )
}

export default ErrorImage
