import React, { useState, useEffect, useContext } from 'react'

import { HiChevronDown } from "react-icons/hi"
import { Menu } from '@headlessui/react'

import moment from 'moment'

import "moment/locale/id"

import { GlobalContext } from '../context'
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLabel, VictoryTooltip } from 'victory'

import { convertToRupiah, getWindowDimensions } from '../functions'

import Loading from '../components/Loading'

import SectionTitle from '../components/Home/SectionTitle'
import PeriodSelectionButton from '../components/Home/PeriodSelectionButton'
import MenuButton from '../components/Home/MenuButton'
import MenuTransition from '../components/Home/MenuTransition'
import NewsSection from '../components/Home/Sections/NewsSection'
import { Link, useLocation } from 'react-router-dom'

import CheckImage from '../assets/svg/CheckImage'
import ErrorImage from '../assets/svg/ErrorImage'

import meterImage from "../assets/img/alatmeter1.png"
import instance from '../helpers/axiosInstance'

export default function Home() {
    let location = useLocation();
    const { meter, setMeter, user, setUser, mainMeter, setMainMeter } = useContext(GlobalContext);

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    const [isOpen, setIsOpen] = useState(false);

    // state loading
    // const [refreshing, setRefreshing] = useState();
    const [isLoading, setIsLoading] = useState();
    const [isHistoryLoading, setIsHistoryLoading] = useState();

    // periode state
    const [period, setPeriod] = useState("harian");

    // grafik
    const [dailyChartData, setDailyChartData] = useState([]);
    const [weeklyChartData, setWeeklyChartData] = useState([]);
    const [monthlyChartData, setMonthlyChartData] = useState([]);

    //  tabel riwayat pemakaian
    const [selectedMonthHistory, setSelectedMonthHistory] = useState("");
    const [monthHistoryList, setMonthHistoryList] = useState(null);
    const [monthlyHistory, loadMonthlyHistory] = useState(null);
    const [monthHistoryOpen, toggleMonthHistoryOpen] = useState(false);

    // total penggunaan
    const [totalUsage, setTotalUsage] = useState(null);
    const [totalPerMonth, setTotalPerMonth] = useState(null);

    // berita
    const [newsData, loadNewsData] = useState(null);

    const loadData = async () => {
        const token = await localStorage.getItem("token");
        const mainMeterId = await localStorage.getItem("mainMeterId");

        const response = await instance.get("/customer");
        
        // console.log(response.data.data.data[0]);
        setUser(response.data.data.data[0].customer_id);
        setMeter(response.data.data.data[0].devices);


        if (mainMeterId) {
            loadSelectedMeter(meter);
            setMainMeter(response.data.data.data[0].devices.filter(
                    device => device.device_id._id === mainMeterId
                )[0]
            );
        }
    }

    const loadSelectedMeter = async (meter) => {
        setIsLoading(true);
        setIsOpen(false);

        setMainMeter(meter);

        const token = await localStorage.getItem("token");

        // get main mater id
        const mainMeterId =  await localStorage.getItem("mainMeterId");

        // load grafik
        const dayData = await instance.get(`/consumtion/bill_consumption/day/${mainMeterId}`);
        const weeklyData = await instance.get(`/consumtion/bill_consumption/week/${mainMeterId}`);
        const monthlyData = await instance.get(`/consumtion/bill_consumption/month/${mainMeterId}`);

        setDailyChartData(dayData.data.data);
        setWeeklyChartData(weeklyData.data.data.reverse());
        setMonthlyChartData(monthlyData.data.data);

        // total konsumsi
        const dailyTotalData = await getDataTotal("day", mainMeterId, token);
        const weeklyTotalData = await getDataTotal("week", mainMeterId, token);
        const monthlyTotalData = await getDataTotal("month", mainMeterId, token);

        setTotalUsage({
            day: dailyTotalData.data.total_per_hari,
            week: weeklyTotalData.data.total_per_minggu,
            month: monthlyTotalData.data.total_per_bulan,
        });

        setSelectedMonthHistory(moment(new Date()).format("MMMM YYYY"));
        refreshMonthlyHistory({ month: new Date().getMonth() + 1, year: new Date().getFullYear() });

        // 3 bulan ke belakang
        const threeLastMonths = await instance.get(`/customer/3bulanKebelakang`);
        setMonthHistoryList(threeLastMonths.data.data.map(data => { return { label: data.label, value: { month: data.value, year: data.year }}; }));

        // berita
        const news = await instance.get(`/customer/news`);
        loadNewsData(news.data.data);

        // total per bulan
        // const totalPerMonthData = await instance.get(`/consumtion/bill_consumption/month_bill/count/${mainMeterId}`);
        // setTotalPerMonth(totalPerMonthData.data.data);

        setIsLoading(false);
    }

    const refreshMonthlyHistory = async ( month ) => {
        setIsHistoryLoading(true);
    
        const mainMeter =  localStorage.getItem("mainMeterId");
    
        const monthHistoryData = await instance.post(`/consumtion/bill_cycle_consumption_history/${mainMeter}`, { ...month });
        console.log(monthHistoryData)
        loadMonthlyHistory(monthHistoryData.data.data);
    
        setIsHistoryLoading(false);
      }

    // fungsi GET total penggunaan meter
    const getDataTotal = async (period, meterId, token) => {
        const dataTotal = await instance.get(`/consumtion/bill_consumption/${period}/count/${meterId}`);

        return dataTotal.data;
    };

    useEffect(() => {
        console.log(location);
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        }
      
        window.addEventListener('resize', handleResize);

        // console.log(windowDimensions);
        setIsLoading(true);
        
        loadData();

        // console.log("ini user:", user)

        setIsLoading(false);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div style={{ height: "100vh", overflowY: "auto" }}>
            <div className='header'>
                <div className='flex justify-between items-center w-full mb-5' style={{ fontSize: 12 }}>
                    <p className='text-white flex-grow text-[13px]'>Pilih meter: </p>

                    <div className="flex items-end">
                        <Menu>
                            <div className='w-full relative'>         
                                <Menu.Button as="div">
                                    <MenuButton onClick={() => setIsOpen((prevOpen) => !prevOpen)} validation={mainMeter} valueTrueText={mainMeter && mainMeter.code} valueFalseText="Pilih meter" />
                                </Menu.Button>

                                <MenuTransition show={isOpen}>
                                    <Menu.Items>
                                        <div className='dropdown-items absolute w-full shadow-xl'>
                                            {meter && meter.map((item, index) => {
                                                // console.log(item);
                                                return (
                                                <Menu.Item
                                                    onClick={() => { localStorage.setItem("mainMeterId", item.device_id._id); loadSelectedMeter(item); }}
                                                    key={"meterSelect-"+index}
                                                    value={item}
                                                >
                                                    {({ disabled, active }) => (
                                                        <div key={item._id} className={`dropdown-item 
                                                            ${index < meter.length - 1 && "border-b border-b-cyan-400 border-solid"}
                                                            ${disabled ? "cursor-not-allowed" : "cursor-pointer"}
                                                            ${active && "dropdown-item-active"}`}
                                                        >
                                                            <span className={`${disabled && "text-gray-300"}`}>{item.code}</span>
                                                        </div>
                                                    )}
                                                </Menu.Item>
                                            )})}
                                        </div>
                                    </Menu.Items>
                                </MenuTransition>
                            </div>
                        </Menu>
                    </div>
                </div>

                {
                    (!isLoading && mainMeter) && (
                        <>
                            <p className='text-white text-center text-lg font-semibold mb-1 mt-2'>Pemakaian Air Bulan Ini</p>
                            <p className='text-white text-xl text-center font-semibold'>{totalUsage && totalUsage.month} m³</p>

                            {/* <p className='text-white text-center font-semibold mb-0.5 mt-3'>Perkiraan Biaya</p>
                            <p className='text-white text-center font-semibold'>{totalPerMonth && convertToRupiah(totalPerMonth.perkiraan_biaya_perbulan)}</p> */}

                            {/* <div className={`py-0.5 px-2 mt-6 ${mainMeter.device_id.status ? "bg-aira-success text-aira-success-text" : "bg-aira-error"} block mx-auto rounded-full flex items-center text-center text-[10px] w-fit`}>
                                {(mainMeter && mainMeter.device_id.status) ? (
                                    <><CheckImage className="mr-2" width={20} height={20} /> Meter normal</>) : (
                                    <><ErrorImage className="mr-2" width={20} height={20} /> Meter bermasalah</>)}
                            </div> */}

                            {/* <img src={meterImage} alt="Gambar Meter" className='mx-auto h-[100px] mt-4' /> */}
                        </>
                    ) 
                }
            </div>

            <div className='h-min flex flex-1 flex-column items-center justify-center'>
                <div className='body-container'>
                    {
                        isLoading ? <Loading /> : !mainMeter ? (
                            <div style={{ padding: "12px 20px", maxWidth: 450, width: windowDimensions }}>
                                <p className='mb-4 text-center'>
                                    Selamat datang, {user && user.name}.
                                </p>

                                <p className='text-center'>
                                    Anda bisa melihat data pemakaian dan rincian data lainnya
                                    dengan memilih meter terlebih dahulu.
                                </p>
                            </div>
                        ) : (
                            <>
                                <div id="graph-section" className='divide-y divide-y-reverse mb-2'>
                                    <SectionTitle text="Pemakaian Air" />

                                    { dailyChartData.length > 0 && (
                                        <VictoryChart animate domainPadding={25} width={windowDimensions <= 450 ? windowDimensions : 450} height={300} padding={{ left: 70, right: 20, top: 10, bottom: 60 }}>
                                            <VictoryBar
                                                barRatio={0.8}
                                                barWidth={25}
                                                alignment="middle" 
                                                data={period === "harian" ? dailyChartData : period === "bulanan" ? monthlyChartData : weeklyChartData}
                                                labels={({datum}) => { return `${datum.device_daily_usage || datum.total_usage} m³`; }}
                                                x={period !== "mingguan" ? "x" : "sampledate"}
                                                y={period === "harian" ? "total_usage" : "device_daily_usage"}
                                                style={{ data: { fill: "#4FC3F7" } }}
                                                labelComponent={<VictoryTooltip dy={-8} cornerRadius={0} pointerLength={0} style={{ fontFamily: "Muli" }} />}
                                            />

                                            <VictoryAxis
                                                tickFormat={(x) => period === "mingguan" ? new Date(x).getDate() + '/' + (new Date(x).getMonth() + 1) : x}
                                                axisLabelComponent={<VictoryLabel style={{ fontFamily: "Muli", fontSize: 12 }} />}
                                                tickLabelComponent={<VictoryLabel style={{ fontFamily: "Muli", fontSize: 12 }} />}
                                                label={`Waktu (dalam ${period === "harian" ? "jam" : period === "bulanan" ? "minggu" : "hari"})`}
                                                style={{
                                                    axisLabel: { padding: 30 }
                                                }}
                                            />
                                            <VictoryAxis dependentAxis
                                                standalone={true}
                                                domain={{ y: [0, .1] }}
                                                axisLabelComponent={<VictoryLabel style={{ fontFamily: "Muli" }} dy={-20 }/>}
                                                tickLabelComponent={<VictoryLabel text={({ datum }) => datum} style={{ fontFamily: "Muli", fontSize: 12 }} />}
                                                label="Pemakaian (dalam m³)"
                                                axisValue={0}
                                            />
                                        </VictoryChart>
                                    )}
                                </div>

                                <div id="period-select-section">
                                    <PeriodSelectionButton selectedPeriod={period === "harian"} period="harian" onClick={() => setPeriod("harian")} text="Harian" />
                                    <PeriodSelectionButton selectedPeriod={period === "mingguan"} period="mingguan" onClick={() => setPeriod("mingguan")} text="Mingguan" />
                                    <PeriodSelectionButton selectedPeriod={period === "bulanan"} period="bulanan" onClick={() => setPeriod("bulanan")} text="Bulanan" />
                                </div>

                                <div id="total-consumption-section" className='flex mt-3 w-full space-x-2'>
                                    <div className='grow p-2 bg-white shadow-lg rounded divide-y border-gray-400 border border-solid'>
                                        <p className='w-full mb-1'>Total per<br /> hari</p>

                                        <p className='text-cyan-400 text-lg pt-1 font-semibold'>{totalUsage && totalUsage.day} m³</p>
                                    </div>

                                    <div className='grow p-2 bg-white shadow-lg rounded divide-y border-gray-400 border border-solid'>
                                        <p className='w-full mb-1'>Total per<br /> minggu</p>

                                        <p className='text-cyan-400 text-lg pt-1 font-semibold'>{totalUsage && totalUsage.week} m³</p>
                                    </div>

                                    <div className='grow p-2 bg-white shadow-lg rounded divide-y border-gray-400 border border-solid'>
                                        <p className='w-full mb-1'>Total per<br /> bulan</p>

                                        <p className='text-cyan-400 text-lg pt-1 font-semibold'>{totalUsage && totalUsage.month} m³</p>
                                    </div>
                                </div>

                                <div id="monthly-usage-section">
                                    <div className='flex w-full justify-between items-center mb-2 mt-3'>
                                        <SectionTitle text="Data Pemakaian" />

                                        <div className="flex items-end" style={{ fontSize: 12 }}>
                                            <Menu>
                                                <div className='w-full relative'>         
                                                    <Menu.Button as="div">
                                                        <button className='dropdown inline-flex items-center justify-between' style={{ flexBasis: "50%" }} onClick={() => toggleMonthHistoryOpen((prevOpen) => !prevOpen)}>
                                                            {selectedMonthHistory ? selectedMonthHistory : "Pilih bulan"}

                                                            <HiChevronDown style={{ marginLeft: 10 }} />
                                                        </button>
                                                    </Menu.Button>

                                                    <MenuTransition show={monthHistoryOpen}>
                                                        <Menu.Items>
                                                            <div className='dropdown-items absolute w-full shadow-xl'>
                                                                {monthHistoryList && monthHistoryList.map((item, index) => (
                                                                    <Menu.Item
                                                                        onClick={() => { toggleMonthHistoryOpen(false); setSelectedMonthHistory(moment(item.value).format("MMMM YYYY")); refreshMonthlyHistory({ month: item.value.month + 1, year: item.value.year }); }}
                                                                        key={"month" + index}
                                                                        value={item.value}
                                                                    >
                                                                        {({ disabled, active }) => (
                                                                            <div key={"month" + index} className={`dropdown-item 
                                                                                ${index < meter.length - 1 && "border-b border-b-cyan-400 border-solid"}
                                                                                ${disabled ? "cursor-not-allowed" : "cursor-pointer"}
                                                                                ${active && "dropdown-item-active"}`}
                                                                            >
                                                                                <span className={`${disabled && "text-gray-300"}`}>{moment(item.value).format("MMMM YYYY")}</span>
                                                                            </div>
                                                                        )}
                                                                    </Menu.Item>
                                                                ))}
                                                            </div>
                                                        </Menu.Items>
                                                    </MenuTransition>
                                                </div>
                                            </Menu>
                                        </div>
                                    </div>

                                    {selectedMonthHistory ? (
                                        <p className='my-1.5'>Untuk melihat detail pemakaian harian, klik salah satu kolom konsumsi harian di bawah ini.</p>
                                    ) : (
                                        <p className='my-1.5'>Untuk melihat pemakaian bulanan, pilih salah satu bulan pemakaian terlebih dahulu.</p>
                                    )}

                                    <div className={`monthly-usage-list ${ (!monthlyHistory || isHistoryLoading || monthlyHistory.length <= 0) && "flex flex-column justify-center items-center" }`}>
                                        {
                                            isHistoryLoading ? (
                                                <div>
                                                    <p className='text-center mb-3'>Memuat pemakaian bulanan...</p>

                                                    <Loading />
                                                </div>
                                            ) : monthlyHistory ? (
                                                monthlyHistory.length > 0 ? (
                                                    <div className='divide-y'>
                                                        <div className='bg-white flex justify-between w-full py-1.5 sticky top-0 -z-100'>
                                                            <p className='font-bold'>Tanggal</p>
                                                            <p className='font-bold'>Pemakaian Air (m³)</p>
                                                        </div>
    
                                                        {
                                                            monthlyHistory.map((item, index) => {
                                                                //  (item);  
                                                                return (
                                                                    <Link key={"monthlyHistory-" + index} to={`/consumption/details/${mainMeter.device_id._id}/${moment(item.sampledate).format("DD")}/${moment(item.sampledate).format("MM")}/${moment(item.sampledate).format("YYYY")}`} state={{ modal: true }}>
                                                                        <div className='flex justify-between w-full py-1.5 -z-200000' key={"consumption" + index}>
                                                                            <p>{moment(item.sampledate).format("L")}</p>
                                                                            <p>{item.device_daily_usage}</p>
                                                                        </div>
                                                                    </Link>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <p className='text-center mb-3'>Tidak ada pemakaian air pada bulan yang dimaksud.</p>
                                                    </div>
                                                )
                                            ) : (
                                                <p>Silakan pilih bulan pemakaian.</p>
                                            )
                                        }
                                    </div>
                                </div>

                                <NewsSection newsData={newsData} />

                                <div className='h-48 w-full'></div>
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    )
}
