import React, { useContext, useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import { GlobalContext } from '../context'

import Home from '../pages/Home'
import ConsumptionDetails from '../pages/Home/ConsumptionDetails'
import NewsDetails from '../pages/Home/NewsDetails'

import Login from '../pages/Login'

import Logout from '../pages/Logout'

// import Meter from '../pages/Meter'

// import News from '../pages/News'

import Profile from '../pages/Profile'
import AccountDetails from '../pages/Profile/AccountDetails'
import ChangePassword from '../pages/Profile/ChangePassword'

export default function AfterLoginRoutes() {
    const { isLoggedIn, setIsLoggedIn } = useContext(GlobalContext);
    const location = useLocation();

    useEffect(async () => {
      const token = await localStorage.getItem("token");

      if (token) {
        setIsLoggedIn(true);
      }

      console.log(isLoggedIn, location)
    })

    return (
      <Routes>
        <Route path="/" element={<Navigate replace to={isLoggedIn ? "/home" : "/login"} />} />
        <Route path="/login" element={<Login />} />

        <Route path="/home" element={isLoggedIn ? <Home /> : <Navigate replace to="/login" />} />
        <Route path="/consumption/details/:meterId/:date/:month/:year" element={isLoggedIn ? <ConsumptionDetails /> : <Navigate replace to="/login" />} />
        <Route path="/news/:id" element={isLoggedIn ? <NewsDetails /> : <Navigate replace to="/login" />} />
        
        {/* <Route path="/news" element={isLoggedIn ? <News /> : <Navigate replace to="/login" />} /> */}
        
        {/* <Route path="/meter" element={isLoggedIn ? <Meter /> : <Navigate replace to="/login" />} /> */}

        <Route path="/profile" element={isLoggedIn ? <Profile /> : <Navigate replace to="/login" />} />
        <Route path="/profile/details" element={isLoggedIn ? <AccountDetails /> : <Navigate replace to="/login" />} />
        <Route path="/change-password" element={isLoggedIn ? <ChangePassword /> : <Navigate replace to="/login" />} />
      
        <Route path="/logout" element={<Logout />} />
      </Routes>
    )
}
