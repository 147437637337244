import React, { useContext, useState, useEffect } from 'react'
import { Transition } from '@headlessui/react'
import { motion } from 'framer-motion'

import { useNavigate } from 'react-router-dom'

import { GlobalContext } from '../context'

import tkr_logo from "../assets/img/tkr-logo.png"

import LoginInput from '../components/Login/LoginInput'
import Loading from '../components/Loading'

import instance from '../helpers/axiosInstance'

export default function Login() {
    const {setIsLoggedIn, setUser, setMeter } = useContext(GlobalContext);

    const [username, changeUserName] = useState("");
    const [password, changePassword] = useState("");
    
    const [loginLoading, setLoginLoading] = useState(false);

    const [isOpen, setIsOpen] = useState(false);

    let navigate = useNavigate();

    const login = async (e) => {
        e.preventDefault();

        setLoginLoading(true);

        try {
            const loginResult = await instance.post("customer/login", {
                email: username,
                password,
            });

            localStorage.setItem("token", loginResult.data.token);
            localStorage.setItem("refreshToken", loginResult.data.refreshToken);
           
            setIsLoggedIn(true);

            const response = await instance.get("mobile/customer");
            
            setUser(response.data.data.data[0].customer_id);
            setMeter(response.data.data.data[0].devices);

            navigate("/home", { replace: true });
        } catch (error) {
            console.log(error.message);

            setIsOpen(true);
            setTimeout(() => { setIsOpen(false); }, 5000);
        }

        setLoginLoading(false);
    };

    useEffect(() => {
        const token = localStorage.getItem("token");

        if (token) {
            setIsLoggedIn(true);
            navigate("/home", { replace: true });
        }
    })

    return (
        <motion.div className='relative' initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}>
            <form className='w-full' id="login-container" onSubmit={login}>
                <img src={tkr_logo} className='h-28 pb-5' alt="TKR logo" />
                <p>Selamat datang di AIRA</p>

                <LoginInput params={{ type: "text", label: "Username", placeholder: "Masukan username", onChange: (e) => changeUserName(e.target.value), autocapitalize: "off" }} />
                <LoginInput params={{ type: "password", label: "Password", placeholder: "Masukan password", onChange: (e) => changePassword(e.target.value), autocapitalize: "off" }} />

                <div className='w-full flex justify-end items-end'>
                    <button type="submit" id="login-button" className='flex-row bg-aira-blue w-1/2 justify-center' disabled={loginLoading} style={{ backgroundColor: loginLoading ? "#777" : "#327FF3" }}>
                       { loginLoading ? <Loading /> : "Masuk" } 
                    </button>
                </div>

                <Transition
                    style={{ position: "fixed", bottom: 0 }}
                    show={isOpen}
                    enter="transition duration-300 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-300 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                >
                    <div className="login-popup login-failed-popup" onClose={() => setIsOpen(false)}>
                        <p>Login Gagal</p>

                        <p>
                            Periksa koneksi internet Anda, atau coba lagi.
                        </p>
                    </div>
                </Transition>
            </form>
        </motion.div>
    )
}
