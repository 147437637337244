import React, { useState } from "react"
import { GlobalContext } from ".";

export const GlobalProvider = ({ children }) => {
    // state
    const [user, setUser] = useState(null);
    const [meter, setMeter] = useState(null);
    const [mainMeter, setMainMeter] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    return (
        <GlobalContext.Provider value={{ user, meter, mainMeter, isLoggedIn, setUser, setMeter, setMainMeter, setIsLoggedIn }}>
            {children}
        </GlobalContext.Provider>
    )
}