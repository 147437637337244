import axios from "axios"

let headers = {}

const instance = axios.create({
    baseURL: "https://swm.galsonic.com/swmapi/api/mobile",
    headers
});

instance.interceptors.request.use(async (config) => {
    const token = await localStorage.getItem("token");
    
    if (token) {
        config.headers = {
            "Authorization": `Bearer ${token}`,
        }
    }

    return config;
})

instance.interceptors.response.use(null, (error) => console.log(error));

export default instance;
