import { Transition } from "@headlessui/react";
import React,  { useContext, useEffect } from "react";
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import BottomTabs from "./components/BottomTabs";

import { GlobalContext } from "./context";

import AfterLoginRoutes from "./routes/AfterLoginRoutes";

function App() {
  const { isLoggedIn, setIsLoggedIn } = useContext(GlobalContext);

  useEffect(async () => {
    const token = await localStorage.getItem("token");

    if (token) {
      setIsLoggedIn(true);
    }

    console.log(isLoggedIn);
  });

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <BrowserRouter>
        <AfterLoginRoutes />

        <Transition
          show={isLoggedIn}
          enter="transition duration-1000 ease-out"
          enterFrom="transform opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-1000 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform opacity-0"
        >
          <BottomTabs />
        </Transition>
      </BrowserRouter>
    </div>
  );
}

export default App;
