import React, { useState } from 'react'
import { Transition } from '@headlessui/react'
import { motion } from 'framer-motion'

import { useNavigate } from 'react-router-dom'

import Loading from '../../components/Loading'
import LoginInput from '../../components/Login/LoginInput'
import { HiChevronLeft } from 'react-icons/hi'

import instance from '../../helpers/axiosInstance'

export default function ChangePassword() {
    const navigate = useNavigate();

    const [oldPassword, changeOldPassword] = useState("");
    const [newPassword, changeNewPassword] = useState("");
    const [confirmNewPassword, changeConfirmNewPassword] = useState("");

    const [errorMessage, setErrorMessage] = useState({});
    
    const [loginLoading, setLoginLoading] = useState(false);

    const [isError, setIsError] = useState(false);

    const changePassword = async (e) => {
        if (validateBlankInput()) {
            showErrorPopup({
                title: "Input Kurang Lengkap",
                text: "Semua input kosong harus diisi."
            });
        } 
        else if (validateOldAndNewPassword()) {
            showErrorPopup({
                title: "Ganti Password Gagal",
                text: "Password baru harus berbeda dengan password lama."
            });
        }
        else if (validateConfirmPassword()) {
            setLoginLoading(true);

            try {
                const token = await localStorage.getItem("token");
                await instance.post(
                    "/customer/customer-password-change", {
                    "password": oldPassword,
                    "new_password": newPassword,
                });
    
                navigate("/profile", { replace: true, state: { message: "Ganti password berhasil." } });
            } catch (error) {
                console.log(error.message);

                showErrorPopup({
                    title: "Ganti Password Gagal",
                    text: "Periksa koneksi internet Anda, atau cek lagi password Anda."
                });
    
                setIsError(true);
                setTimeout(() => { setIsError(false); }, 5000);
            }
    
            setLoginLoading(false);
        } else {
            showErrorPopup({
                title: "Ganti Password Gagal",
                text: "Konfirmasi password baru harus sama dengan password baru yang dimasukkan."
            });
        }
    };

    const showErrorPopup = (errorMsg) => {
        setErrorMessage(errorMsg);

        setIsError(true);
        setTimeout(() => { setIsError(false); }, 5000);
    }

    const validateConfirmPassword = () => newPassword === confirmNewPassword;

    const validateOldAndNewPassword = () => oldPassword === newPassword;

    const validateBlankInput = () => oldPassword === "" || newPassword === "" || confirmNewPassword === "";

    return (
        <motion.div className='relative' initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}>
            <div className='bg-aira-blue shadow-md sticky top-0'>
                <div id="header-top-section" className='mx-auto'>
                    <button className='p-[10px]' onClick={() => navigate("/profile", { state: null })}>
                        <span className='text-white'><HiChevronLeft /></span>                      
                    </button>

                    <p className='text-white font-bold'>Ganti Password</p>

                    <button className={`${!loginLoading ? "bg-white text-aira-blue" : "bg-gray-500 text-white"} p-1 mx-[5px] rounded-md `} onClick={() => changePassword()} disabled={loginLoading}>
                        { loginLoading ? <Loading /> : "Simpan" }
                    </button>
                </div>
            </div>

            <form className='w-full' id="change-password-container" onSubmit={changePassword}>
                <LoginInput params={{ type: "password", label: "Password Lama", placeholder: "Masukan password lama", onChange: (e) => changeOldPassword(e.target.value), autocapitalize: "off" }} />
                <LoginInput params={{ type: "password", label: "Password Baru", placeholder: "Masukan password baru", onChange: (e) => changeNewPassword(e.target.value), autocapitalize: "off" }} />
                <LoginInput params={{ type: "password", label: "Konfirmasi Password Baru", placeholder: "Masukan konfirmasi password baru", onChange: (e) => changeConfirmNewPassword(e.target.value), autocapitalize: "off" }} />

                <Transition
                    style={{ position: "fixed", bottom: 0 }}
                    show={isError}
                    onClick={() => setIsError(false)}
                    enter="transition duration-300 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-300 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                >
                    <div className="aira-popup aira-error-popup cursor-pointer" onClose={() => setIsError(false)}>
                        <p>{errorMessage !== {} && errorMessage.title}</p>

                        <p>
                            {errorMessage !== {} && errorMessage.text}
                        </p>
                    </div>
                </Transition>
            </form>
        </motion.div>
    )
}
