import React from 'react'
import { HiChevronDown } from 'react-icons/hi'

export default function MenuButton({ onClick, validation, valueTrueText, valueFalseText }) {
    return (
        <button className='dropdown inline-flex items-center justify-between' style={{ flexBasis: "50%" }} onClick={onClick}>
            {validation ? valueTrueText : valueFalseText}

            <HiChevronDown style={{ marginLeft: 10 }} />
        </button>
    )
}
