import moment from "moment";
import "moment/locale/id";

export const formatDate = (date) => {
    return moment(date).format("LLLL");
}

export const formatTime = (date) => {
    return moment(date).format("LT");
}

export const formatMonthAndYear = (date) => {
    return moment(date).format("MMMM YYYY");
}

export const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;

    return { width, height };
}

export const convertToRupiah = (moneyValue) => {
    return "Rp " + moneyValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}