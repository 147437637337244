import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { HiChevronLeft } from 'react-icons/hi';

import moment from 'moment';
import "moment/locale/id";

import Loading from '../../components/Loading';
import { motion } from 'framer-motion';
import TopBar from '../../components/All/TopBar';
import instance from '../../helpers/axiosInstance';

export default function ConsumptionDetails() {
  const { meterId, date, month, year } = useParams();

  const navigate = useNavigate();

  const [usageData, loadUsageData] = useState(null);

  const [isUsageLoading, setIsUsageLoading] = useState(false);

  const loadData = async () => {
    setIsUsageLoading(true);

    const token = await localStorage.getItem("token");

    const usage = await instance.get(`/consumtion/bill_consumption/hour/${meterId}?day=${date}&month=${month}&year=${year}`);

    // console.log(usage.data.data.data);
    loadUsageData(usage.data.data.data);
    setIsUsageLoading(false);
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <motion.div className='relative' initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}>
      <TopBar
        title="Detail Pemakaian Harian"
        leftButton={(<button className='p-[10px]' onClick={() => navigate(-1)}>
                       <span className='text-white'><HiChevronLeft /></span>
                     </button>)}
        rigthButton={(<div className="w-[24px]"></div>)}
      />

      <div className='body-container'>
        <div id="consumption-details-section" className='bg-white shadow-lg rounded-lg h-[65vh] overflow-y-auto'>
          {isUsageLoading ? (
            <div className='justify-center items-center flex-column h-40'>
              <p className='mt-20 mb-2 text-center'>Memuat pemakaian harian...</p>

              <Loading />
            </div>
          ) :
            (<>
              <div className='sticky top-0 z-20000 bg-white px-[20px] pt-[12px]'>
                <p className='font-bold'>Tanggal Pemakaian</p>
                <p className='mb-2'>{`${date}/${month}/${year}`}</p>

                <div style={{ height: 1, backgroundColor: "#222", width: "100%" }} />

                <div className='flex justify-around p-1'>
                  <span style={{ flex: .33, textAlign: "center", fontSize: 13 }}>Waktu</span>

                  <span style={{ flex: .33, textAlign: "center", fontSize: 13 }}>Pemakaian (m³)</span>

                  <span style={{ flex: .33, textAlign: "center", fontSize: 13 }}>Stand (m³)</span>
                </div>

                <div style={{ height: 2, backgroundColor: "#4FC3F7", width: "100%" }} />
              </div>

              <div className='px-[20px]'>
                {
                  usageData && usageData.map((item, index) => {
                    // console.log(item);
                    return (
                      <div className='flex justify-around p-1' key={"daily-" + index}>
                        <span style={{ flex: .33, textAlign: "center", fontSize: 13 }}>{moment(item.samplehour).format('LT')}</span>

                        <span style={{ flex: .33, textAlign: "center", fontSize: 13 }}>{item.total_usage}</span>

                        <span style={{ flex: .33, textAlign: "center", fontSize: 13 }}>{item.total_stand}</span>
                      </div>
                    )
                  })
                }

                <div style={{ height: 1, backgroundColor: "#222", width: "100%", marginBottom: 6 }} />

                <div className='mt-[3px] flex justify-between mb-[12px]'>
                  <span style={{ fontSize: 13, flex: 0.5 }}>Total pemakaian</span>

                  <span className='text-right' style={{ fontSize: 13, flex: 0.5 }}>{usageData && usageData.reduce((x, a) => { return x + a.total_usage }, 0).toFixed(3)} m³</span>
                </div>
              </div>

            </>)
          }
        </div>

        <div className='h-24' />
      </div>
    </motion.div>
  );
}
