import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../context';

export default function Logout() {
  let navigate = useNavigate();

  const { setUser, setMeter, setMainMeter, setIsLoggedIn } = useContext(GlobalContext);

  useEffect(() => {
    localStorage.clear();
    setUser(null);
    setMeter(null);
    setMainMeter(null);
    setIsLoggedIn(false);

    navigate("/login", { replace: true });
  }, []); 

  return <div></div>;
}
