import React, { useEffect, useState } from 'react';

import { getWindowDimensions } from '../../../functions';

import SectionTitle from '../SectionTitle';
import NewsItem from '../NewsItem';
import { Link } from 'react-router-dom';

export default function NewsSection({ newsData }) {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        }
      
        window.addEventListener('resize', handleResize);
    }, []);

    return (
        <div id="news-section" className='w-full mt-4'>
            <SectionTitle text="Informasi Berita" />

            <div className='flex overflow-x-auto space-x-4 scroll-mx-0.5 p-0.5'>
                {
                    newsData && newsData.map((item, index) => {
                        // console.log(item);
                        
                        return (
                            <Link to={`/news/${item._id}`} state={item} key={"newsItem-" + index}>
                                <NewsItem key={"news-" + item._id} item={item} style={{ width: windowDimensions.width, maxWidth: (newsData && newsData.length > 1) ? 380 : 404 }} />
                            </Link>
                        )
                    })
                }
            </div>
        </div>
    )
}
