import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { HiChevronLeft } from 'react-icons/hi';

import "moment/locale/id";

import { motion } from 'framer-motion';
import TopBar from '../../components/All/TopBar';

export default function NewsDetails() {
//   const { id } = useParams();
  const location = useLocation();

  const navigate = useNavigate();

  return (
    <motion.div className='relative' initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}>
      <TopBar
        title="Detail Berita"
        leftButton={(<button className='p-[10px]' onClick={() => navigate(-1)}>
                       <span className='text-white'><HiChevronLeft /></span>
                     </button>)}
        rigthButton={(<div className="w-[24px]"></div>)}
      />

      <div className='body-container'>
        <div id="news-details-section" className='mt-0 h-[100vh] shadow-md '>
          <img className='h-[300px] w-full object-cover' src={location.state.img_url} /> 
          
          <div className='p-[12px]'>
            <h1 className='font-bold leading-[24px] mb-2'>{location.state.title}</h1>
            <p className='leading-[24px]'>{location.state.text}</p>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
