import React from 'react'

export default function LoginInput({ params }) {
    const { type, placeholder, label, onChange, autocapitalize } = params;

    return (
        <div className='login-input w-full'>
            <div>
                <label htmlFor='username' className='login-input-label'>{label}</label>
            </div>

            <input
                type={type}
                placeholder={placeholder}
                className='login-input-field w-full'
                onChange={onChange}
                autoCapitalize={autocapitalize}
                required
            />
        </div>
    )
}
