import React from 'react'

export default function NewsItem({ item, style }) {
    return (
        <div className='flex-shrink-0 bg-white rounded-lg overflow-hidden shadow-md mb-3 mt-0.5 ml-0.5 border border-gray-500 border-solid' style={style}>
            <img src={item.img_url} alt={item.img_name} className='h-52 object-cover w-full select-none pointer-events-none' />

            <div className='p-3'>
                <h3 className='font-bold text-[13px] mb-2 truncate'>{ item.title }</h3>
                <p className='text-[13px] line-clamp-3' style={{ height: 72 }}>{ item.text }</p>
            </div>
        </div>
    )
}
