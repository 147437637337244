// import React, { useContext, useState, Fragment, useEffect } from 'react';
import React, { useContext } from 'react';
import { GlobalContext } from '../../context';

import DefaultImage from "../../assets/img/userimg.png";

import { useNavigate, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

import { HiChevronLeft } from 'react-icons/hi';

export default function AccountDetails() {
    const { state } = useLocation();
    const navigate = useNavigate();

    const { user } = useContext(GlobalContext);

    // console.log(user);

    // useEffect(() => {
    //     console.log(state);
    // }, []);

    return (
        <>
            <div className='header'>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className='absolute top-2 left-2 p-2 text-white cursor-pointer' onClick={() => navigate("/profile", { state: null })}
                    >
                        <HiChevronLeft />
                    </motion.div>

                {
                    <img
                        src={user.img_url || DefaultImage}
                        alt={user.img_name || "profile picture"}
                        className="w-20 h-20 mx-auto mt-2"
                    />
                }

                <p className='text-white text-[13px] text-center mt-3'>{user.name}</p>
                <p className='text-white text-[13px] text-center'>{user.code}</p>
            </div>

            <div className='h-min flex flex-1 flex-column items-center justify-center'>
                <div className='body-container'>
                    <div id="profile-menu-section">
                        <motion.div
                            initial={{ opacity: 0, translateY: -50 }}
                            animate={{ opacity: 1, translateY: 0 }}
                        >
                            <div className='menu-item w-full bg-white shadow-lg border-gray-500 border border-solid p-2 rounded-md text-[13px]'>
                                <span className='text-gray-500 text-[10px]'>Nama Lengkap</span><br />
                                <span>{state.name || "-"}</span>
                            </div>
                        </motion.div>

                        <motion.div
                            initial={{ opacity: 0, translateY: -50 }}
                            animate={{ opacity: 1, translateY: 0 }}
                            transition={{ delay: 0.150 }}
                        >
                            <div className='menu-item w-full bg-white shadow-lg border-gray-500 border border-solid p-2 rounded-md text-[13px] mt-3'>
                                <span className='text-gray-500 text-[10px]'>Email</span><br />
                                <span>{state.email || "-"}</span>
                            </div>
                        </motion.div>

                        <motion.div
                            initial={{ opacity: 0, translateY: -50 }}
                            animate={{ opacity: 1, translateY: 0 }}
                            transition={{ delay: 0.300 }}
                        >
                            <div className='menu-item w-full bg-white shadow-lg border-gray-500 border border-solid p-2 rounded-md text-[13px] mt-3'>
                                <span className='text-gray-500 text-[10px]'>Alamat</span><br />
                                <span>{state.address || "-"}</span>
                            </div>
                        </motion.div>

                        <div className='h-8 w-full'></div>
                    </div>
                </div>
            </div>
        </>
    )
}
