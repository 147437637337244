import React from 'react';

export default function TopBar({ leftButton, title, rigthButton }) {
  return (
        <div className='bg-aira-blue shadow-md sticky top-0 z-[200000]'>
            <div id="header-top-section" className='mx-auto'>
                {leftButton}

                <p className='text-white font-bold'>{title}</p>

                {rigthButton}
            </div>
        </div>
    );
}
